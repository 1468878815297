import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Alert } from "reactstrap"
import styled from "styled-components"
import Select from "react-select"
import { Link } from "gatsby"

import {colors, site, seo} from "../../theme"

const tmjbooks = [
  {
    value: "https://amzn.to/3FD0gP6",
    label: "Faroese. A language course for beginners -- Hjalmar P. Petersen ",
  },
  {
    value: "https://amzn.to/3FA1lXM",
    label:
      "English-Faroese Learner's Dictionary -- Multi Linguis",
  },
  {
    value: "https://amzn.to/3M2yvBG",
    label: "Faroese Pocket Dictionary -- John Shapiro",
  },
  {
    value: "https://amzn.to/3L42nfV",
    label: "Faroese-English Dictionary: With Faroese Folk-Lore and Proverbs -- G. V. C. Young",
  },
  {
    value: "https://amzn.to/39GXyfg",
    label: "Beginner's Icelandic with Online Audio -- Helga Hilmisdóttir",
  }
]

class TMJBooks extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedOption: null }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(selectedOption) {
    this.setState({ selectedOption })
    window.open(selectedOption.value, `_blank`, `noopener`)
    console.log(`Option selected:`, selectedOption)
  }

  render() {
    const { selectedOption } = this.state

    return (
      <Select
        placeholder="Select a book..."
        onChange={this.handleChange}
        value={selectedOption}
        options={tmjbooks}
        isOptionDisabled={(option) => option.disabled === "yes"}
      />
    )
  }
}

function BuyButton(props) {
  return (
    <div style={{ width: `100%`, textAlign: `center` }}>
      <BuyNow href={props.url} target="_blank" rel="noopener noreferrer">
        {props.text}
      </BuyNow>
    </div>
  )
}

const AboutPage = () => (
  <Layout>
    <SEO
      title={seo.resources.title}
      description={seo.resources.description}
      url={site.url + "/resources/"}
    />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0">
          <PurpleSection>
            <AboutContent>
              <h1 className="text-center" style={{ fontFamily: `Jost` }}>
              {seo.resources.title}
              </h1>
            </AboutContent>
          </PurpleSection>
        </Col>
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <p>
              <b>
                Are you looking for the best resources and tools for learning Faroese? 🇫🇴
              </b>
            </p>
            <p>
              On this page you'll find my list of favorite and most recommended products
              for the Faroese language. There are several great
              books, websites and courses out there to choose from but I've
              personally selected a few based on my experience.
            </p>
            <Disclaimer>
              <b>
                <u>Full transparency</u>:
              </b>{" "}
              Some links I provide are affiliate links.
              My main mission is to give you free and useful content, but my blog is a for-profit business. This means that if I can earn a commission by
              referring you to something, I will do so.
            </Disclaimer>
            <ShortLine />
            <SubHeading>Online Faroese Courses</SubHeading>
            <p>
              <b>Cudoo:</b> Cudoo has a great introductory course for Faroese that covers all the essentials. This should be your first starting point. 
            </p>
            <BuyButton
              text="See Cudoo"
              url="/goto/cudoo"
            />
            <SubHeading>
              Best Books To Learn Faroese
            </SubHeading>
            <p>
              Here are some quick links to my
              favorite books (on Amazon):
            </p>
            <TMJBooks />
            <SubHeading>
              Faroese Lessons
            </SubHeading>
            <p>
              <b>italki:</b> Essential to any serious Faroese study is having someone to practice with! With italki, you can take online lessons or conversations sessions with a native Faroese speaker.
              </p>
            <BuyButton
              text="See italki"
              url="/goto/italki"
            />
            <SubHeading>Other useful Faroese resources</SubHeading>
            <p>
              <b>Memrise:</b> There are some decent community courses for Faroese on Memrise that cover some useful vocabulary on various topics.
            </p>
            <BuyButton
              text="See Memrise"
              url="/goto/memrise"
            />
            <ShortLine />
            <p>That's everything for now.</p>
            <p>
              If there's anything I absolutely should add to this list, please{" "}
              <Link to="/contact/">contact me</Link> and let me know.
            </p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const HeroImage = styled.div`
  position: relative;
`
const AboutContent = styled.div`
  max-width: 800px;
  margin: auto;
  padding-bottom: ;
`
const PurpleSection = styled.div`
  //background-image: linear-gradient(316deg, #1e365c 0%, #131313 84%);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  h1 {
    -webkit-text-fill-color: transparent;
  background: ${colors.entrytitle};
  -webkit-background-clip: text;
  color: #fff;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.25;
  }
  p {
    color: #fff !important;
  }
  a {
    color: #f56545;
    &:hover {
      color: #d25337 !important;
    }
  }
`
const SubHeading = styled.h2`
  margin-top: 1em;
  color: ${colors.highlight};
  text-align: center;
`
const Disclaimer = styled(Alert)`
  border: none !important;
  color: #fff !important;
  background-color: ${colors.highlight} !important;
  font-style: italic;
`
const BuyNow = styled.a`
  font-size: 100%;
  font-weight: 700;
  cursor: pointer;
  display: block;
  max-width: 300px;
  margin-top: 15px;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
  padding: 10px 5px 10px 5px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-transform: uppercase;
  color: #fff !important;
  border-radius: 6px;
  background: ${colors.lightbg};
  box-shadow: 0 1px 3px #000;
  &:hover {
    color: #fff !important;
    box-shadow: none;
  }
`
